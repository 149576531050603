import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
import IMGIRScorner from '../../images/new/aboutus.jpeg'
const WhoWeServe = () => {
  return (
    <div>
      <Layout>
        <SEO title="Who We Serve" />
        <PageStructure
          title="Who We Serve"
          desc="TESTIG"
          mode={ABOUTUS}
          image={IMGIRScorner}
        />
      </Layout>
    </div>
  )
}

export default WhoWeServe
